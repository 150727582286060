* {
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
    font-family: var(--fontFamilyBase);
    color: var(--fontBaseColor);
    background: var(--backgroundColor);

    &.Header--NavOpen {
        overflow: hidden;

        @include media(md) {
            overflow: visible;
        }
    }

    &.IframePage {
        overflow:hidden;
    }
}

body {
    font-size: 1.6rem;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    &.no-scroll {
        overflow: hidden;
    }

    .Header--NavOpen & {
        overflow: hidden;

        @include media(md) {
            overflow: visible;
        }
    }

    @media print {
        font-size: 1.4rem;
    }
}

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

@media print {
    @page {
        size: auto;
        margin: 1.5cm;
        border: none;
    }
}

i {
    font-style: italic;
}

// Stolen with pride from bootstrap
.sr-only {
    position: absolute;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

// Next.js hacks
.ArticleList__LazyImgWrapper.is-visible::before,
.CardMedia__LazyImgWrapper.is-visible::after {
    display: none;
}

.TextColumns__Text p {
    margin-bottom: $gutter;
}

:root {
    --breakpointSm: #{map-get($breakpoints, 'sm')};
    --breakpointMd: #{map-get($breakpoints, 'md')};
    --breakpointLg: #{map-get($breakpoints, 'lg')};
}
